import React from 'react';
import { Button, Modal, Group, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const DeleteAttach = ({ onDelete, agentOwnerId = false }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    if (!agentOwnerId) return;
    close();
    onDelete();
    navigate('/listcompetition');
  };

  const DeleteButton = () => {
    const button = (
      <Button
        variant="outline"
        color={agentOwnerId ? "red" : "gray"}
        size="sm"
        leftIcon={<IconTrash size="1rem" />}
        onClick={agentOwnerId ? open : undefined}
        disabled={!agentOwnerId}
      >
        Delete Agent
      </Button>
    );

    if (!agentOwnerId) {
      return (
        <Tooltip label="Only the agent owner can delete this agent" position="top">
          {button}
        </Tooltip>
      );
    }

    return button;
  };

  return (
    <>
      <DeleteButton />

      <Modal 
        opened={opened} 
        onClose={close} 
        title="Confirm Agent Deletion" 
        centered
      >
        <Text size="sm" mb="xl">
          Are you sure you want to delete this agent?
        </Text>
        <Group position="right">
          <Button variant="default" onClick={close}>
            Cancel
          </Button>
          <Button color="red" onClick={handleDelete}>
            Delete Agent
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default DeleteAttach;