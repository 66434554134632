import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { TextInput, PasswordInput, Button, Stack, Text, Anchor, Title, Container, Paper, LoadingOverlay, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAuth } from 'context/AuthContext';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX, IconAlertCircle } from '@tabler/icons-react';
import authService from 'services/authService';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState(null);
  const navigate = useNavigate();
  const { setIsAuthenticated, setCurrentUser } = useAuth();
  const recaptchaRef = useRef();
  const { confirmToken } = useParams();
  const location = useLocation();

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validate: {
      username: (value) => (value.length < 3 ? 'Username must be at least 3 characters' : null),
      password: (value) => (value.length < 6 ? 'Password must be at least 6 characters' : null),
    },
  });

  useEffect(() => {
    const confirmEmail = async () => {
      if (confirmToken) {
        setLoading(true);
        try {
          const response = await axios.get(`/api/register/${confirmToken}`);
          setConfirmationStatus({ type: 'success', message: response.data.message });
          notifications.show({
            title: 'Account Confirmed',
            message: 'Your account has been successfully confirmed. You can now log in.',
            color: 'green',
            icon: <IconCheck size={16} />,
          });
        } catch (error) {
          setConfirmationStatus({ 
            type: 'error', 
            message: error.response?.data?.message || error.response?.data?.error || 'Failed to confirm email'
          });
          notifications.show({
            title: 'Confirmation Failed',
            message: error.response?.data?.message || 'Failed to confirm email',
            color: 'red',
            icon: <IconX size={16} />,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    confirmEmail();
  }, [confirmToken]);

  const handleSubmit = async (values) => {
    const recaptchaToken = recaptchaRef.current.getValue();
    if (!recaptchaToken) {
      notifications.show({
        title: 'Error',
        message: 'Please complete the reCAPTCHA',
        color: 'red',
        icon: <IconX size={16} />,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await authService.login(values.username, values.password, recaptchaToken);
      setCurrentUser({ username: response.username });
      setIsAuthenticated(true);
      notifications.show({
        title: 'Success',
        message: 'Login successful',
        color: 'green',
        icon: <IconCheck size={16} />,
      });
      navigate('/listcompetition');
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message,
        color: 'red',
        icon: <IconX size={16} />,
      });
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
    }
  };

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
        Welcome back!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Don't have an account yet?{' '}
        <Anchor size="sm" onClick={() => navigate('/login-mail/register')}>
          Create account
        </Anchor>
      </Text>

      {confirmationStatus && (
        <Alert 
          icon={confirmationStatus.type === 'success' ? <IconCheck size={16} /> : <IconAlertCircle size={16} />}
          title={confirmationStatus.type === 'success' ? "Email Confirmed" : "Confirmation Status"}
          color={confirmationStatus.type === 'success' ? 'green' : 'red'}
          mt="md"
        >
          {confirmationStatus.message}
        </Alert>
      )}

      <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
        <LoadingOverlay visible={loading} overlayblur={2} />
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Stack>
            <TextInput
              label="Username"
              placeholder="Your username"
              required
              {...form.getInputProps('username')}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              {...form.getInputProps('password')}
            />
            <Anchor 
              component="button" 
              type="button" 
              color="dimmed" 
              onClick={() => navigate('/login-mail/forgot-password')} 
              size="xs"
            >
              Forgot password?
            </Anchor>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lesv0wqAAAAAC-m-_VYbGziay11h0LVlq7qCnpB"
            />
            <Button fullWidth mt="xl" type="submit">
              Sign in
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default SignIn;