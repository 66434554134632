import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const POLLING_STATUSES = ['uploading', 'deploying', 'deploy_queue', 'deploy_run'];
const POLLING_INTERVAL = 1000;

const initialAttachmentState = {
  name: "",
  status: "",
  last_status_message: "",
  queue_info: {},
  run_info: {}
};

export const useAgentAttachment = (competitionId, attachmentId) => {
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(null);
  const [attachment, setAttachment] = useState(initialAttachmentState);
  const [pollTimer, setPollTimer] = useState(null);
  const [temporaryStatus, setTemporaryStatus] = useState(null);


  const handleTemporaryStatus = useCallback((isUploading) => {
    if (isUploading) {
      setTemporaryStatus({
        status: 'uploading',
        last_status_message: 'Uploading files...'
      });
    } else {
      setTemporaryStatus(null); // Reset to use actual status
    }
  }, []);

  const getCurrentAttachment = useCallback(() => {
    if (temporaryStatus) {
      return {
        ...attachment,
        status: temporaryStatus.status,
        last_status_message: temporaryStatus.last_status_message
      };
    }
    return attachment;
  }, [temporaryStatus, attachment]);

  const fetchAttachment = useCallback(async () => {
    try {
      const response = await axios.get(
        `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}/status`
      );
      
      const attachmentData = response.data.attachment || response.data;
      setAttachment(attachmentData);
      setError(null);

      // Start polling if status is in POLLING_STATUSES
      if (POLLING_STATUSES.includes(attachmentData?.status)) {
        if (!pollTimer) {
          const timer = setTimeout(() => {
            fetchAttachment();
          }, POLLING_INTERVAL);
          setPollTimer(timer);
        }
      } else {
        // Clear polling if status is not in POLLING_STATUSES
        if (pollTimer) {
          clearTimeout(pollTimer);
          setPollTimer(null);
        }
      }

      return attachmentData;
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch attachment status');
      // Clear polling on error
      if (pollTimer) {
        clearTimeout(pollTimer);
        setPollTimer(null);
      }
      throw err;
    } finally {
      setIsFetching(false);
    }
  }, [competitionId, attachmentId, pollTimer]);

  const handleDeleteAttach = async () => {
    try {
      await axios.delete(
        `/api/direct_attache_agents/competition/${competitionId}/${attachmentId}`
      );
      window.location.href = `/viewcompetition/${competitionId}`;
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to delete attachment');
      throw err;
    }
  };

  // Initial fetch and cleanup
  useEffect(() => {
    fetchAttachment();

    return () => {
      if (pollTimer) {
        clearTimeout(pollTimer);
      }
    };
  }, [fetchAttachment]);

  return {
    attachment: getCurrentAttachment(),
    isFetching,
    error,
    fetchAttachment,
    handleDeleteAttach,
    handleTemporaryStatus
  };
};

export default useAgentAttachment;