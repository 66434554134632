import React, { useState } from 'react';
import { Card, Group, Text, Stack, Modal, List, ThemeIcon, Button, Tooltip } from '@mantine/core';
import { IconTrash, IconFileText, IconEdit, IconDeviceFloppy, IconUpload } from '@tabler/icons-react';
import { Editor } from '@monaco-editor/react';
import { Dropzone } from '@mantine/dropzone';

const FileManagement = ({
  files = {},
  loading = false,
  disabled = false,
  onDelete,
  onEdit,
  onUpload
})  => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [tempCode, setTempCode] = useState('');
  const [currentEditingFile, setCurrentEditingFile] = useState(null);

  const editorOptions = {
    minimap: { enabled: false },
    lineNumbers: 'on',
    readOnly: disabled || loading,
    automaticLayout: true,
  };

  const handleCodeChange = (newValue) => {
    setTempCode(newValue);
  };

  const handleOpenEditor = (filename) => {
    setShowCodeEditor(true);
    setCurrentEditingFile(filename);
    setTempCode(files[filename].content);
  };

  const handleCloseEditor = () => {
    setShowCodeEditor(false);
    setCurrentEditingFile(null);
    setTempCode('');
  };

  const handleSaveAndClose = () => {
    onEdit(currentEditingFile, tempCode);
    setShowCodeEditor(false);
    setCurrentEditingFile(null);
    setTempCode('');
  };

  const confirmDelete = (file) => {
    setSelectedFile(file);
    setShowConfirmModal(true);
  };

  const deleteFile = () => {
    onDelete(selectedFile);
    setShowConfirmModal(false);
  };

  const handleDrop = (droppedFiles) => {
    droppedFiles.forEach(file => onUpload(file));
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files || []);
    selectedFiles.forEach(file => onUpload(file));
  };

  const renderFileList = () => (
    <List spacing="xs">
      {Object.entries(files).map(([filename, fileData]) => (
        <List.Item
          key={filename}
          icon={
            <ThemeIcon color={filename === 'agent.py' ? 'blue' : 'gray'} size={24} radius="xl">
              <IconFileText size={16} />
            </ThemeIcon>
          }
        >
          <Group position="apart" noWrap>
            <Text size="sm">
              {filename}
              {filename === 'agent.py' && ' (Main File)'}
            </Text>
            <Group spacing={8} noWrap>
              {!fileData.is_binary && (
                <Tooltip label="Edit Code">
                  <Button
                    variant="light"
                    size="xs"
                    color="blue"
                    onClick={() => handleOpenEditor(filename)}
                    disabled={disabled || loading}
                  >
                    <IconEdit size={14} />
                  </Button>
                </Tooltip>
              )}
              <Tooltip label="Delete File">
                <Button
                  variant="light"
                  size="xs"
                  color="red"
                  onClick={() => confirmDelete(filename)}
                  disabled={disabled || loading}
                >
                  <IconTrash size={14} />
                </Button>
              </Tooltip>
            </Group>
          </Group>
        </List.Item>
      ))}
    </List>
  );

  const renderCodeEditorModal = () => (
    <Modal
      opened={showCodeEditor}
      onClose={handleCloseEditor}
      title={`Edit ${currentEditingFile}`}
      size="xl"
      padding="md"
    >
      <Stack spacing="md">
        <Editor
          height="600px"
          language={files[currentEditingFile]?.language || 'plaintext'}
          theme="vs-dark"
          value={tempCode}
          options={editorOptions}
          onChange={(newValue) => setTempCode(newValue)}
        />
        <Group position="right">
          <Button variant="light" onClick={handleCloseEditor}>
            Cancel
          </Button>
          <Button
            variant="filled"
            onClick={handleSaveAndClose}
            leftIcon={<IconDeviceFloppy size={14} />}
          >
            Save & Close
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
  return (
    <Stack spacing="md">
      {/* Main Upload Area */}
      <Card withBorder>
        <Stack spacing="md">
          <Group position="apart">
            <Text weight={500}>Upload Files</Text>
            <Tooltip label="Select Files">
              <Button
                component="label"
                leftIcon={<IconUpload size={16} />}
                disabled={disabled || loading}
                variant="light"
              >
                Browse Files
                <input
                  type="file"
                  onChange={handleFileSelect}
                  multiple
                  style={{ display: 'none' }}
                  disabled={disabled || loading}
                />
              </Button>
            </Tooltip>
          </Group>

          <Dropzone
            onDrop={handleDrop}
            onReject={(files) => console.log('rejected files', files)}
            maxSize={50 * 1024 ** 2}
            accept={[]} // Accept all file types
            disabled={loading || disabled}
            styles={(theme) => ({
              root: {
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4],
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              },
            })}
          >
            <Group position="center" spacing="xl" style={{ minHeight: 80, pointerEvents: 'none' }}>
              <div style={{ textAlign: 'center' }}>
                <Text size="md" inline>
                  Drag files here or click to select
                </Text>
                <Text size="sm" color="dimmed" inline mt={7}>
                  Upload your model files and any additional resources
                </Text>
              </div>
            </Group>
          </Dropzone>
        </Stack>
      </Card>

      {/* File List */}
      {Object.keys(files).length > 0 && (
    <Card withBorder>
      <Text weight={500} mb="md">Uploaded Files</Text>
      {renderFileList()}
    </Card>
  )}

      {/* Delete Confirmation Modal */}
      <Modal
        opened={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title="Confirm Delete"
        size="sm"
      >
        <Stack>
          <Text>Are you sure you want to delete "{selectedFile}"?</Text>
          <Group position="right">
            <Button variant="light" onClick={() => setShowConfirmModal(false)}>
              Cancel
            </Button>
            <Button color="red" onClick={deleteFile}>
              Delete
            </Button>
          </Group>
        </Stack>
      </Modal>

      {/* Code Editor Modal */}
      {renderCodeEditorModal()}
    </Stack>
  );
};

export default FileManagement;