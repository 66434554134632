import { Stack, Title, Group, Button, Modal, Paper, Text, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useGeneralDiscussions from 'hooks/useGeneralDiscussions';
import { useState } from 'react';
import { ArrowUpDown, MessageCircle } from 'lucide-react';
import DiscussionDetail from 'components/Discussion/DiscussionDetail';
import DiscussionEditor from 'components/Discussion/DiscussionEditor';
import DiscussionCard from 'components/Discussion/DiscussionCard';
import { useAuth } from 'context/AuthContext';

const GeneralDiscussions = () => {
  const { isAuthenticated, currentUser, isAdmin } = useAuth();
  const [selectedDiscussionId, setSelectedDiscussionId] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [sortBy, setSortBy] = useState('recent');
  
  const {
    discussions,
    loading,
    error,
    createDiscussion,
    voteDiscussion,
    updateDiscussion,
    deleteDiscussion,
    fetchDiscussions
  } = useGeneralDiscussions();

  const handleSortChange = (value) => {
    setSortBy(value);
    fetchDiscussions(value);
  };

  const handleCreateDiscussion = async (title, content) => {
    await createDiscussion(title, content);
    close();
  };

  const selectedDiscussion = discussions.find(d => d.id === selectedDiscussionId);

  if (loading) return <Text>Loading discussions...</Text>;
  if (error) return <Text color="red">{error}</Text>;

  if (selectedDiscussionId && selectedDiscussion) {
    return (
      <DiscussionDetail
        isAuthenticated={isAuthenticated}
        currentUser={currentUser}
        discussion={selectedDiscussion}
        onBack={() => setSelectedDiscussionId(null)}
        updateDiscussion={updateDiscussion}
        deleteDiscussion={deleteDiscussion}
        voteDiscussion={voteDiscussion}
      />
    );
  }

  return (
    <Stack spacing="xl">
      <Group justify="space-between">
        <Title order={2}>General Discussions</Title>
        {isAuthenticated && (
          <Button 
            onClick={open}
            leftIcon={<MessageCircle size={16} />}
          >
            New Discussion
          </Button>
        )}
      </Group>

      <Group align="flex-start">
        <Select
          label="Sort by"
          value={sortBy}
          onChange={handleSortChange}
          data={[
            { value: 'recent', label: 'Most Recent' },
            { value: 'trending', label: 'Trending' }
          ]}
          leftSection={<ArrowUpDown size={16} />}
          w={200}
        />
      </Group>

      {discussions.length === 0 ? (
        <Paper p="xl" withBorder>
          <Stack align="center" spacing="md">
            <MessageCircle size={32} />
            <Text align="center" c="dimmed">
              No discussions found
            </Text>
          </Stack>
        </Paper>
      ) : (
        <Stack>
          {discussions.map(discussion => (
            <DiscussionCard
              key={discussion.id}
              discussion={discussion}
              onVote={() => voteDiscussion(discussion.id)}
              onClick={() => setSelectedDiscussionId(discussion.id)}
            />
          ))}
        </Stack>
      )}

      <Modal 
        opened={opened} 
        onClose={close} 
        title="Create Discussion"
        size="xl"
      >
        <DiscussionEditor
          onSubmit={handleCreateDiscussion}
          onCancel={close}
        />
      </Modal>
    </Stack>
  );
};

export default GeneralDiscussions;