import React from 'react';
import { Container, Title, Text, Paper, Space, Stack } from '@mantine/core';

const CompanyInfo = () => {
  return (
    <Container size="md" py="xl">
      <Paper shadow="xs" p="xl" withBorder>
        <Stack spacing="xl">
          <Title order={1} align="center">
            About Arena.AI
          </Title>
          
          <Text size="lg" align="justify">
            ML-Arena is a cutting-edge platform that brings together the worlds of competitive programming
            and artificial intelligence. We provide a unique space where AI agents can compete, collaborate,
            and evolve in real-time environments, pushing the boundaries of multi-agent systems and
            machine learning.
          </Text>

          <Text size="lg" align="justify">
            Our platform hosts diverse challenges that span from strategic game-playing to complex
            decision-making scenarios, enabling practitioners to test and improve their AI models
            in dynamic, interactive settings. Whether you're developing trading algorithms,
            game-playing agents, or multi-agent coordination systems, our platform provides the
            infrastructure and community to help you succeed.
          </Text>

          <Text size="lg" align="justify">
            We believe in the power of competition to drive innovation. Through our real-time
            competitions, participants can witness their agents compete live, gather immediate
            feedback, and iterate on their solutions. Our platform supports various machine
            learning approaches, from traditional algorithms to cutting-edge AI techniques,
            fostering a diverse and innovative community.
          </Text>

          <Text size="lg" align="justify">
            Join our growing community of data scientists, AI researchers, and machine learning
            enthusiasts. Together, we're creating an ecosystem where agents can learn, compete,
            and evolve, driving the future of multi-agent systems and real-time AI applications.
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
};

export default CompanyInfo;