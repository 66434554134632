import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  TextInput, 
  PasswordInput,
  Button, 
  Stack, 
  Text, 
  Anchor, 
  Title, 
  Container, 
  Paper, 
  LoadingOverlay,
  Alert 
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX, IconAlertCircle } from '@tabler/icons-react';
import authService from 'services/authService';
import axios from 'axios';

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [isResetMode, setIsResetMode] = useState(false);
  const navigate = useNavigate();
  const { resetToken } = useParams();

  // Form for requesting password reset
  const requestForm = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  // Form for setting new password
  const resetForm = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => (value.length < 6 ? 'Password must be at least 6 characters' : null),
      confirmPassword: (value, values) => 
        value !== values.password ? 'Passwords do not match' : null,
    },
  });

  useEffect(() => {
    if (resetToken) {
      setIsResetMode(true);
    }
  }, [resetToken]);

  const handleRequestReset = async (values) => {
    setLoading(true);
    try {
      await authService.forgotPassword(values.email);
      notifications.show({
        title: 'Success',
        message: 'If this email exists, password reset instructions will be sent.',
        color: 'green',
        icon: <IconCheck size={16} />,
      });
      navigate('/login-mail/sign-in');
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.message,
        color: 'red',
        icon: <IconX size={16} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (values) => {
    setLoading(true);
    try {
      await axios.post(`/api/auth/reset-password/${resetToken}`, {
        password: values.password,
      });
      
      notifications.show({
        title: 'Success',
        message: 'Your password has been successfully updated. You can now log in with your new password.',
        color: 'green',
        icon: <IconCheck size={16} />,
      });
      
      navigate('/login-mail/sign-in');
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error.response?.data?.message || 'Failed to reset password',
        color: 'red',
        icon: <IconX size={16} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container size={420} my={40}>
      <Title align="center" sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}>
        {isResetMode ? 'Reset Password' : 'Forgot Password'}
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Remember your password?{' '}
        <Anchor size="sm" onClick={() => navigate('/login-mail/sign-in')}>
          Login
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md" pos="relative">
        <LoadingOverlay visible={loading} overlayblur={2} />
        {isResetMode ? (
          <form onSubmit={resetForm.onSubmit(handlePasswordReset)}>
            <Stack>
              <PasswordInput
                label="New Password"
                placeholder="Enter your new password"
                required
                {...resetForm.getInputProps('password')}
              />
              <PasswordInput
                label="Confirm Password"
                placeholder="Confirm your new password"
                required
                {...resetForm.getInputProps('confirmPassword')}
              />
              <Button fullWidth mt="xl" type="submit">
                Set New Password
              </Button>
            </Stack>
          </form>
        ) : (
          <form onSubmit={requestForm.onSubmit(handleRequestReset)}>
            <Stack>
              <TextInput
                label="Email"
                placeholder="Your email"
                required
                {...requestForm.getInputProps('email')}
              />
              <Text size="xs" color="dimmed">
                Enter your email address and we'll send you a link to reset your password.
              </Text>
              <Button fullWidth mt="xl" type="submit">
                Request Password Reset
              </Button>
            </Stack>
          </form>
        )}
      </Paper>
    </Container>
  );
};

export default ForgotPassword;