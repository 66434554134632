import React, { useState } from 'react';
import { Group, Switch, Tooltip } from '@mantine/core';
import { IconLock, IconLockOpen } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import axios from 'axios';

const AgentVisibilityToggle = ({ 
  agentId,
  currentUser,
  agentOwnerId,
  initialVisibility = false,
}) => {
  const [isPublic, setIsPublic] = useState(initialVisibility);
  const [isUpdating, setIsUpdating] = useState(false);

  const canToggleVisibility = currentUser && currentUser.user_id === agentOwnerId;

  const handleVisibilityToggle = async (checked) => {
    if (!canToggleVisibility) {
      return;
    }

    setIsUpdating(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/direct_attache_agents/agent/${agentId}/visibility`,
        { is_public: checked }
      );

      if (response.status === 200) {
        setIsPublic(checked);
        notifications.show({
          title: 'Visibility Updated',
          message: `Agent is now ${checked ? 'public' : 'private'}`,
          color: 'green'
        });
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to update visibility',
        color: 'red'
      });
      setIsPublic(!checked); // Revert the toggle
    } finally {
      setIsUpdating(false);
    }
  };

  const getTooltipLabel = () => {
    if (!canToggleVisibility) {
      return "Only the agent owner can change visibility";
    }
    return isPublic 
      ? "Make your agent private (hide files and comments from community)"
      : "Share your agent files";
  };

  return (
    <Tooltip
      label={getTooltipLabel()}
      position="left"
      multiline
      width={220}
    >
      <Group spacing="xs">
        <Switch
          size="md"
          disabled={!canToggleVisibility || isUpdating}
          checked={isPublic}
          onChange={(event) => handleVisibilityToggle(event.currentTarget.checked)}
          thumbIcon={
            isPublic 
              ? <IconLockOpen size={12} stroke={2.5} />
              : <IconLock size={12} stroke={2.5} />
          }
          color="blue"
          label={isPublic ? "Public" : "Private"}
        />
      </Group>
    </Tooltip>
  );
};

export default AgentVisibilityToggle;