import { useState, useEffect } from 'react';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { Box, Button, Text, Group, Divider, Paper } from '@mantine/core';

const PostEditor = ({ 
  onSubmit, 
  onCancel,
  initialContent = '', 
  submitLabel = 'Post', 
  placeholder = 'Share your thoughts, ask questions, or discuss ideas...' 
}) => {
  const [resetKey, setResetKey] = useState(0);

  const editor = useEditor({
    extensions: [
      StarterKit,       
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: 'text-blue-500 hover:underline cursor-pointer',
        },
      })
    ],
    content: initialContent,
    editorProps: {
      attributes: {
        class: 'prose max-w-none focus:outline-none',
        placeholder: placeholder,
      },
    },
  });

  useEffect(() => {
    if (editor && initialContent !== editor.getHTML()) {
      editor.commands.setContent(initialContent);
    }
  }, [initialContent, editor]);

  const handleSubmit = async () => {
    if (editor) {
      const content = editor.getHTML();
      await onSubmit(content);
      
      // Only reset if this is not an edit (i.e., no initialContent)
      if (!initialContent) {
        editor.commands.setContent('');
        setResetKey(prev => prev + 1);
      }
    }
  };

  return (
    <div key={resetKey}>
      <RichTextEditor editor={editor}>
        {/* Top Actions Bar */}
        <Group justify="space-between" p="xs" className="bg-gray-50 border-b">
          <Group spacing="xs">
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H2 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.CodeBlock />
              <RichTextEditor.Blockquote />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
            </RichTextEditor.ControlsGroup>
          </Group>

          <Group spacing="xs">
            {onCancel && (
              <Button 
                variant="subtle" 
                onClick={onCancel}
                size="sm"
              >
                Cancel
              </Button>
            )}
            <Button 
              onClick={handleSubmit}
              disabled={!editor?.getText()?.trim()}
              size="sm"
            >
              {submitLabel}
            </Button>
          </Group>
        </Group>

        {/* Editor Content */}
        <Box p="md">
          <RichTextEditor.Content />
        </Box>
      </RichTextEditor>
    </div>
  );
};

// PostContent component remains the same
const PostContent = ({ content }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          class: 'text-blue-500 hover:underline cursor-pointer',
        },
      })
    ],
    content,
    editable: false,
  });

  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

export { PostEditor as default, PostContent };