import { useState, useEffect } from 'react';
import axios from 'axios';

export const useAgentDeploy = (competitionId, attacheAgentId, onStatusChange) => {
  const [deployInfo, setDeployInfo] = useState({
    deploymentLimits: {
      daily_run_limit: 0,
      daily_runs_used: 0,
      daily_runs_remaining: 0,
      weekly_deploy_limit: 0,
      weekly_deploys_used: 0,
      weekly_deploys_remaining: 0,
      can_deploy: false
    },
    latestDeploy: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dailyRunAllocation, setDailyRunAllocation] = useState(0);
  const [currentAllocation, setCurrentAllocation] = useState(0);
  const [availableAllocation, setAvailableAllocation] = useState(0);

  const fetchAllocationInfo = async () => {
    try {
      const response = await axios.get(`/api/attaches/agent/my_agents/${attacheAgentId}/allocation`);
      const { current_allocation, available_allocation, _ } = response.data;
      setCurrentAllocation(current_allocation);
      setAvailableAllocation(available_allocation);
      if (dailyRunAllocation === 0) {
        setDailyRunAllocation(current_allocation);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch allocation info');
    }
  };

  const fetchDeployInfo = async () => {
    try {
      const response = await axios.get(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/deploy`
      );
      setDeployInfo(response.data);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to fetch deployment info');
    } finally {
      setIsLoading(false);
    }
  };

  const deployAgent = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `/api/direct_attache_agents/competition/${competitionId}/${attacheAgentId}/deploy/${dailyRunAllocation}`
      );
      onStatusChange();
      return response.data;
    } catch (err) {
      setError(err.response?.data?.error || 'Deployment failed');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchDeployInfo(),
        fetchAllocationInfo()
      ]);
      setIsLoading(false);
    };
    
    fetchInitialData();
  }, [competitionId, attacheAgentId]);

  return {
    deploymentLimits: deployInfo.deploymentLimits,
    latestDeploy: deployInfo.latestDeploy,
    isLoading,
    error,
    deployAgent,
    refreshDeployInfo: fetchDeployInfo,
    currentAllocation,
    availableAllocation,
    dailyRunAllocation,
    setDailyRunAllocation,
  };
};