import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import axios from 'axios';
import { AppShell, Burger, Group, Button, Text, Menu, ActionIcon, Box, Container } from '@mantine/core';
import { IconChevronDown, IconLogout } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';


axios.defaults.baseURL = `${process.env.REACT_APP_PREFIX_BACKEND}`;

const Shell = ({ children }) => {
  const { currentUser, isAdmin, isAuthenticated, setIsAuthenticated, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure();

  const handleLogout = async () => {
    try {
      await axios.post('/api/auth/logout');
      setCurrentUser(null);
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const NavButton = ({ to, children }) => (
    <Button
      component={NavLink}
      to={to}
      variant="subtle"
      color="blue"
      styles={(theme) => ({
        root: {
          '&': {
            '&[data-active]': {
              backgroundColor: theme.colors.blue[0],
              color: theme.colors.blue[7],
            },
          },
        },
      })}
    >
      {children}
    </Button>
  );

  const NavItems = () => (
    <>
      <NavButton to="/listcompetition">Competitions</NavButton>
      <NavButton to="/my-agents">My Agents</NavButton>
      <NavButton to="/general-discussions">Discussions</NavButton>
      {isAdmin && (
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button variant="subtle" color="blue" rightSection={<IconChevronDown size={14} />}>
              Admin
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item component={NavLink} to="/listagent">Agents</Menu.Item>
            <Menu.Item component={NavLink} to="/listenvironment">Environments</Menu.Item>
            <Menu.Item component={NavLink} to="/listprofile">Profiles</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header>
        <Container size="xl">
          <Group h="100%" justify="space-between">
            <Group>
              <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
              <Text size="lg" fw={700} component={NavLink} to="/listcompetition" style={{ textDecoration: 'none', color: 'inherit' }}>
                ML-Arena
              </Text>
            </Group>
            <Group visibleFrom="sm">
              <NavItems />
            </Group>
            {isAuthenticated ? (
              <Group>
                <NavButton to="/my-profile">{currentUser.username}</NavButton>
                <ActionIcon variant="subtle" color="gray" onClick={handleLogout}>
                  <IconLogout size={18} />
                </ActionIcon>
              </Group>
            ) : (
              <Button component={NavLink} to="/login" variant="subtle" color="blue">
                Login
              </Button>
            )}
          </Group>
        </Container>
      </AppShell.Header>

      <AppShell.Navbar p="md">
        <Box hiddenFrom="sm">
          <NavItems />
        </Box>
      </AppShell.Navbar>

      <AppShell.Main>
        {children}
      </AppShell.Main>
    </AppShell>
  );
};

export default Shell;