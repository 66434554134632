import React, { useState, useEffect } from 'react';
import { Container, Card, Text, Group, Button, TextInput, Tooltip, CopyButton, Stack, Title, Divider, ActionIcon, Textarea } from '@mantine/core';
import { IconCopy, IconKey, IconBrandGithub, IconWorld, IconDeviceFloppy } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

const Profile = () => {
  const [profile, setProfile] = useState({
    username: '',
    email: '',
    created_at_ts: '',
    key_id: '',
    key_pass: '',
    github_page: '',
    personal_website: '',
    bio: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/profile/`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setProfile(data);
        setEditedProfile(data);
      })
      .catch((error) => notifications.show({ 
        message: `Error fetching profile: ${error.message}`, 
        color: 'red' 
      }));
  }, []);

  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/profile/update`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedProfile),
      });

      const data = await response.json();

      if (response.ok) {
        setProfile(editedProfile);
        setIsEditing(false);
        notifications.show({ message: 'Profile updated successfully', color: 'green' });
      } else {
        notifications.show({ message: data.error || 'Failed to update profile', color: 'red' });
      }
    } catch (error) {
      notifications.show({ message: `Error updating profile: ${error.message}`, color: 'red' });
    }
  };

  const generateNewKey = () => {
    fetch(`${process.env.REACT_APP_PREFIX_BACKEND}/api/auth/generate_new_key`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.key_id) {
          setProfile((prevProfile) => ({
            ...prevProfile,
            key_id: data.key_id,
            key_pass: data.key_pass,
          }));
          notifications.show({ message: 'New API key generated successfully', color: 'green' });
        } else {
          notifications.show({ message: 'Failed to generate new API keys', color: 'red' });
        }
      })
      .catch((error) => notifications.show({ message: `Error generating new API keys: ${error.message}`, color: 'red' }));
  };

  const ApiKeyInput = ({ label, value }) => (
    <TextInput
      label={label}
      value={value}
      readOnly
      rightSection={
        <CopyButton value={value} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
              <ActionIcon color={copied ? 'teal' : 'blue'} onClick={copy} size="lg">
                <IconCopy size={20} />
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      }
    />
  );

  return (
    <Container size="sm" py="xl">
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <Group position="apart" mb="md">
          <Title order={2}>User Profile</Title>
          <Button 
            variant={isEditing ? "filled" : "light"}
            onClick={() => isEditing ? handleSave() : setIsEditing(true)}
            leftIcon={isEditing ? <IconDeviceFloppy size={20} /> : null}
          >
            {isEditing ? 'Save Changes' : 'Edit Profile'}
          </Button>
        </Group>

        <Stack spacing="md">
          <Group grow>
            <TextInput
              label="Username"
              value={isEditing ? editedProfile.username : profile.username}
              onChange={(e) => setEditedProfile({
                ...editedProfile,
                username: e.target.value
              })}
              readOnly={!isEditing}
            />
            <TextInput 
              label="Email" 
              value={profile.email} 
              readOnly 
            />
          </Group>

          <TextInput
            label="GitHub Page"
            icon={<IconBrandGithub size={20} />}
            value={isEditing ? editedProfile.github_page : profile.github_page}
            onChange={(e) => setEditedProfile({
              ...editedProfile,
              github_page: e.target.value
            })}
            readOnly={!isEditing}
            placeholder="Your GitHub page"
          />

          <TextInput
            label="Personal Website"
            icon={<IconWorld size={20} />}
            value={isEditing ? editedProfile.personal_website : profile.personal_website}
            onChange={(e) => setEditedProfile({
              ...editedProfile,
              personal_website: e.target.value
            })}
            readOnly={!isEditing}
            placeholder="https://your-website.com"
          />

          <Textarea
            label="Bio"
            value={isEditing ? editedProfile.bio : profile.bio}
            onChange={(e) => setEditedProfile({
              ...editedProfile,
              bio: e.target.value
            })}
            readOnly={!isEditing}
            placeholder="Tell us about yourself"
            minRows={3}
          />

          <TextInput 
            label="Account Created" 
            value={profile.created_at_ts} 
            readOnly 
          />
          
          <Divider my="sm" label="API Access" labelPosition="center" />
          
          {profile.key_id && (
            <Stack spacing="xs">
              <ApiKeyInput label="Key ID" value={profile.key_id} />
              <ApiKeyInput label="Key Pass" value={profile.key_pass} />
            </Stack>
          )}
          
          <Button onClick={generateNewKey}>
            <Group spacing="xs">
              <IconKey size={20} />
              <Text>Generate New API Key</Text>
            </Group>
          </Button>
        </Stack>
      </Card>
    </Container>
  );
};

export default Profile;