import React from 'react';
import { Card, Title, Group, Button, Stack } from '@mantine/core';
import Editor from "@monaco-editor/react";
import { IconCopy } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

const OverviewAgentEnvironmentInteraction = ({ competitionInfo, environmentInfo }) => {
  const isSinglePlayer = competitionInfo.number_of_agents === 1;

  const agentCode = isSinglePlayer
    ? `import your_files
    class Agent:
    def __init__(self, env):
        self.env = env

    def choose_action(self, observation, reward=0.0, terminated=False, truncated=False, info=None, action_mask=None):
        action = self.env.action_space.sample()
        return action`
    : `import your_files
    class Agent:
    def __init__(self, env):
        self.env = env
        self.action_space = self.env.action_space(self.env.agents[0])

    def choose_action(self, observation, reward=0.0, terminated=False, truncated=False, info=None, action_mask=None):
        action = self.action_space.sample(action_mask)
        return action`;

  const interactionCode = isSinglePlayer
    ? `${environmentInfo.model_code_import}
observation, info = env.reset()
cum_reward = 0

# Instantiate your agent
my_agent = Agent(env)

for _ in range(10):
    action = my_agent.choose_action(observation)
    observation, reward, terminated, truncated, info = env.step(action)
    cum_reward += reward
    if terminated or truncated:
        observation, info = env.reset()

env.close()`
    : `${environmentInfo.model_code_import}
cum_reward = {agent: 0 for agent in env.agents}

# Instantiate your agent (assuming first agent is controlled by your agent)
my_agent = Agent(env)

# Interact with the environment
for agent in env.agent_iter():
    observation, reward, termination, truncation, info = env.last()
    cum_reward[agent] += reward
    if termination or truncation:
        action = None
    else:
        action_mask = observation["action_mask"]
        if agent == env.agents[0]:  # Use your agent's policy for the first agent
            action = my_agent.choose_action(observation, action_mask=action_mask)
        else:  # Random action for other agents
            action = env.action_space(agent).sample(mask)
    env.step(action)

env.close()
print(cum_reward)`;

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    notifications.show({
      title: 'Copied to clipboard',
      message: 'Code has been copied successfully',
      color: 'green'
    });
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack spacing="lg">
        <Group position="apart">
          <Title order={3}>The agent.py file structure required</Title>
          <Button 
            variant="light"
            leftIcon={<IconCopy size={16} />}
            onClick={() => handleCopy(agentCode)}
            size="sm"
          >
            Copy
          </Button>
        </Group>
        <Editor
          height="200px"
          defaultLanguage="python"
          value={agentCode}
          options={{
            readOnly: true,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            fontSize: 14,
            lineNumbers: 'on',
            renderLineHighlight: 'none',
            folding: false
          }}
        />

        <Group position="apart">
          <Title order={3}>The Environment</Title>
          <Button 
            variant="light"
            leftIcon={<IconCopy size={16} />}
            onClick={() => handleCopy(interactionCode)}
            size="sm"
          >
            Copy
          </Button>
        </Group>
        <Editor
          height="300px"
          defaultLanguage="python"
          value={interactionCode}
          options={{
            readOnly: true,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            fontSize: 14,
            lineNumbers: 'on',
            renderLineHighlight: 'none',
            folding: false
          }}
        />
      </Stack>
    </Card>
  );
};

export default OverviewAgentEnvironmentInteraction;