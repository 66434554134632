import React from 'react';
import { Box, Text, Container, Group, Anchor } from '@mantine/core';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={(theme) => ({
        borderTop: `1px solid ${theme.colors.gray[2]}`,
        marginTop: theme.spacing.xl,
        paddingTop: theme.spacing.md,
        paddingBottom: theme.spacing.md,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      })}
    >
      <Container size="xl">
        <Group position="apart">
          <Text size="sm" color="dimmed">
            © 2024 ML-Arena. All rights reserved.
          </Text>
          <Anchor component={Link} to="/company" size="sm" color="blue">
            About ML-Arena.com
          </Anchor>
        </Group>
      </Container>
    </Box>
  );
};

export default Footer;