import React, { useEffect } from 'react';
import { Stack, Group, Text, NumberInput, Alert } from '@mantine/core';
import { ChevronUp, ChevronDown, AlertCircle } from 'lucide-react';

const DeployAllocationControl = ({ 
    value,
    onChange,
    currentAllocation,
    availableAllocation,
    isDisabled
}) => {
    // Ensure value is at least 1 on mount and when dependencies change
    useEffect(() => {
        if (value < 1) {
            onChange(1);
        }
    }, [value, onChange]);

    // Calculate maximum allowed allocation
    const maxAllowed = currentAllocation + availableAllocation;
    
    // Validate if current value is valid for deployment
    const isValidForDeployment = value >= 1 && value <= maxAllowed;

    const handleIncrease = () => {
        if (!isDisabled && value < maxAllowed) {
            onChange(value + 1);
        }
    };

    const handleDecrease = () => {
        if (!isDisabled && value > 1) {
            onChange(value - 1);
        }
    };

    // Handle direct input changes with validation
    const handleInputChange = (newValue) => {
        const parsedValue = parseInt(newValue);
        if (isNaN(parsedValue)) {
            onChange(1);
        } else {
            onChange(Math.min(Math.max(1, parsedValue), maxAllowed));
        }
    };

    return (
        <Stack spacing="sm">
            <Text size="sm" weight={500}>Daily Run Allocation</Text>
            
            <Group position="apart" align="center" className="w-full">
                <Group spacing="sm" align="center">
                    <NumberInput
                        value={value}
                        onChange={handleInputChange}
                        min={1}
                        max={maxAllowed}
                        step={1}
                        className="w-24"
                        styles={{ 
                            input: { 
                                textAlign: 'center',
                                backgroundColor: !isValidForDeployment ? 'var(--mantine-color-red-0)' : undefined
                            } 
                        }}
                        rightSection={
                            <Stack spacing={0}>
                                <ChevronUp
                                    size={14}
                                    className={`cursor-pointer ${
                                        isDisabled || value >= maxAllowed 
                                            ? 'opacity-30' 
                                            : 'hover:opacity-80'
                                    }`}
                                    onClick={handleIncrease}
                                />
                                <ChevronDown
                                    size={14}
                                    className={`cursor-pointer ${
                                        isDisabled || value <= 1 
                                            ? 'opacity-30' 
                                            : 'hover:opacity-80'
                                    }`}
                                    onClick={handleDecrease}
                                />
                            </Stack>
                        }
                        rightSectionWidth={30}
                        disabled={isDisabled}
                    />
                    
                    <Stack spacing={0}>
                        <Text size="xs" color="dimmed">Current: {currentAllocation}</Text>
                        <Text size="xs" color="dimmed">Available: {availableAllocation}</Text>
                    </Stack>
                </Group>
            </Group>

            {!isValidForDeployment && (
                <Alert 
                    icon={<AlertCircle size={16} />}
                    color="red" 
                    variant="light"
                    className="mt-2"
                >
                    {value < 1 
                        ? "Allocation must be at least 1 run per day"
                        : `Maximum allowed allocation is ${maxAllowed} runs per day`
                    }
                </Alert>
            )}

            <Text size="xs" color="dimmed">
                Allocate how many runs per day this agent should perform during the competition (you can update this value later)
            </Text>
        </Stack>
    );
};

export default DeployAllocationControl;