import { useState, useEffect, useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

const useDiscussionCompetitionPosts = (isAuthenticated, competitionId, discussionId) => {
  const [discussion, setDiscussion] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchWithAuth = async (url, options = {}) => {
    const isWrite = options.method && options.method !== 'GET';
    
    if (isWrite && !isAuthenticated) {
      navigate('/login');
      throw new Error('Authentication required');
    }

    const response = await fetch(url, {
      ...options,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'Request failed');
    }
    
    return response.json();
  };

  const fetchDiscussionAndPosts = useCallback(async () => {
    if (!competitionId || !discussionId) return;

    setLoading(true);
    try {
      const data = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}`
      );
      
      setDiscussion({
        ...data,
        posts: undefined
      });
      setPosts(data.posts || []);
      setError(null);
    } catch (err) {
      setError(err.message);
      notifications.show({
        title: 'Error',
        message: 'Failed to load discussion and posts',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  }, [competitionId, discussionId]);

  const createPost = async (content, parentId = null) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    // Create optimistic post
    const optimisticPost = {
      id: `temp-${Date.now()}`,
      content,
      parent_id: parentId,
      created_at_ts: new Date().toISOString(),
      upvote_count: 0
    };

    setPosts(prev => [...prev, optimisticPost]);

    try {
      const data = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}/posts`,
        {
          method: 'POST',
          body: JSON.stringify({ content, parent_id: parentId }),
        }
      );
      
      // Replace optimistic post with real one
      setPosts(prev => [
        ...prev.filter(p => p.id !== optimisticPost.id),
        data
      ]);

      notifications.show({
        title: 'Success',
        message: 'Post created successfully',
        color: 'green'
      });
      
      return data;
    } catch (err) {
      // Remove optimistic post on error
      setPosts(prev => prev.filter(p => p.id !== optimisticPost.id));

      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };

  const updatePost = async (postId, content) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    // Optimistically update post content
    setPosts(prev => prev.map(post => {
      if (post.id === postId) {
        return { ...post, content };
      }
      return post;
    }));

    try {
      const data = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}/posts/${postId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ content }),
        }
      );
      
      // Update with server data
      setPosts(prev => prev.map(post => 
        post.id === postId ? data : post
      ));

      notifications.show({
        title: 'Success',
        message: 'Post updated successfully',
        color: 'green'
      });
    } catch (err) {
      // Revert on error - fetch fresh data
      await fetchDiscussionAndPosts();
      
      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };

  const deletePost = async (postId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    // Optimistically remove post
    const previousPosts = [...posts];
    setPosts(prev => prev.filter(p => p.id !== postId));

    try {
      await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}/posts/${postId}`,
        { method: 'DELETE' }
      );
      
      notifications.show({
        title: 'Success',
        message: 'Post deleted successfully',
        color: 'green'
      });
    } catch (err) {
      // Restore posts on error
      setPosts(previousPosts);
      
      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };

  const votePost = async (postId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  
    // Find the current post
    const post = posts.find(p => p.id === postId);
    if (!post) return;
  
    // Calculate new vote state
    const isVoted = post.has_voted;
    const newVoteCount = post.upvote_count + (isVoted ? -1 : 1);
  
    // Optimistically update
    setPosts(prev => prev.map(p => {
      if (p.id === postId) {
        return {
          ...p,
          upvote_count: newVoteCount,
          has_voted: !isVoted
        };
      }
      return p;
    }));
  
    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}/posts/${postId}/vote`,
        { method: 'POST' }
      );
  
      // Update with server response
      setPosts(prev => prev.map(p => {
        if (p.id === postId) {
          return {
            ...p,
            upvote_count: response.upvote_count,
            has_voted: response.message === "Vote added"
          };
        }
        return p;
      }));
    } catch (err) {
      // Revert on error
      setPosts(prev => prev.map(p => {
        if (p.id === postId) {
          return {
            ...p,
            upvote_count: post.upvote_count,
            has_voted: isVoted
          };
        }
        return p;
      }));
  
      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };
  useEffect(() => {
    if (competitionId && discussionId) {
      fetchDiscussionAndPosts();
    }
  }, [competitionId, discussionId, fetchDiscussionAndPosts]);

  return {
    discussion,
    posts,
    loading,
    error,
    createPost,
    updatePost,
    deletePost,
    votePost,
    refreshDiscussion: fetchDiscussionAndPosts,
  };
};

export default useDiscussionCompetitionPosts;