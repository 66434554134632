import React from 'react';
import { Stack, Tabs, Group, Box } from '@mantine/core';
import { LayoutDashboard, Trophy, Settings } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import AgentResultsDashboard from 'components/DirectAgentAttach/Monitor/AgentResultsDashboard';
import AllocationControl from 'components/DirectAgentAttach/Monitor/AllocationControl';
import Configuration from 'components/DirectAgentAttach/Monitor/Configuration';
import AgentResultOverview from 'components/DirectAgentAttach/Monitor/AgentResultOverview';
import LeaderboardTab from 'pages/Competition/LeaderboardTab';

const AgentMonitor = ({
  competitionId,
  attacheAgentId,
  status,
  onStatusChange,
  currentUser,
  isAdmin,
  isAuthenticated,
  agentOwnerId,
  initialVisibility
}) => {
  const location = useLocation();
  const defaultTab = location.state?.defaultTab || 'dashboard';

  return (
    <Stack spacing="md">
      <Group justify="flex-end" align="flex-start">
        <AllocationControl
          attacheAgentId={attacheAgentId}
          isStarted={status === 'active'}
          handleStart={onStatusChange}
          isStartBlocked={false}
          isOwner={agentOwnerId == currentUser.user_id}
        />
      </Group>

      <Tabs defaultValue={defaultTab}>
        <Tabs.List>
          <Tabs.Tab 
            value="dashboard" 
            leftSection={<LayoutDashboard size={16} />}
          >
            Dashboard
          </Tabs.Tab>
          <Tabs.Tab 
            value="leaderboard"
            leftSection={<Trophy size={16} />}
          >
            Leaderboard
          </Tabs.Tab>
          <Tabs.Tab 
            value="configuration"
            leftSection={<Settings size={16} />}
          >
            Configuration
          </Tabs.Tab>
        </Tabs.List>

        <Box mt="md">
          <Tabs.Panel value="dashboard">
            <Stack spacing="md">
              <AgentResultOverview competitionId={competitionId} attacheAgentId={attacheAgentId} />
              <AgentResultsDashboard competitionId={competitionId} attacheAgentId={attacheAgentId} />
            </Stack>
          </Tabs.Panel>
          
          <Tabs.Panel value="leaderboard">
            <LeaderboardTab competitionId={competitionId} />
          </Tabs.Panel>
          
          <Tabs.Panel value="configuration">
            <Configuration 
              competitionId={competitionId} 
              attacheAgentId={attacheAgentId} 
              currentUser={currentUser} 
              isAdmin={isAdmin} 
              isAuthenticated={isAuthenticated} 
              agentOwnerId={agentOwnerId}
              initialVisibility={initialVisibility}
            />
          </Tabs.Panel>
        </Box>
      </Tabs>
    </Stack>
  );
};

export default AgentMonitor;