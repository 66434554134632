import { useState, useEffect } from "react";
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../context/AuthContext";

const useGeneralDiscussions = () => {
  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  
  const fetchWithAuth = async (url, options = {}) => {
    const isWrite = options.method && options.method !== 'GET';
    
    if (isWrite && !isAuthenticated) {
      navigate('/login');
      throw new Error('Authentication required');
    }

    const response = await fetch(url, {
      ...options,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'Request failed');
    }
    
    return response.json();
  };

  const fetchDiscussions = async (sortBy = 'recent') => {
    setLoading(true);
    try {
      const discussionsData = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/general?sort_by=${sortBy}`
      );
      
      setDiscussions(discussionsData);
      setError(null);
    } catch (err) {
      setError(err.message);
      notifications.show({
        title: 'Error',
        message: 'Failed to load discussions',
        color: 'red'
      });
    } finally {
      setLoading(false);
    }
  };
  
  const createDiscussion = async (title, content, tags = []) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const optimisticDiscussion = {
      id: `temp-${Date.now()}`,
      title,
      content,
      content_html: content,
      tags,
      created_at_ts: new Date().toISOString(),
      upvote_count: 0,
      post_count: 0,
      has_voted: false
    };

    setDiscussions(prev => [optimisticDiscussion, ...prev]);

    try {
      const data = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/general`,
        {
          method: 'POST',
          body: JSON.stringify({ title, content, tags }),
        }
      );
      
      setDiscussions(prev => [
        data,
        ...prev.filter(d => d.id !== optimisticDiscussion.id)
      ]);

      notifications.show({
        title: 'Success',
        message: 'Discussion created successfully',
        color: 'green'
      });
      
      return data;
    } catch (err) {
      setDiscussions(prev => prev.filter(d => d.id !== optimisticDiscussion.id));
      
      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };
  
    const updateDiscussion = async (discussionId, { title, content }) => {
      if (!isAuthenticated) {
        navigate('/login');
        return;
      }
  
      const originalDiscussion = discussions.find(d => d.id === discussionId);
      if (!originalDiscussion) return;
  
      setDiscussions(prev => prev.map(d => {
        if (d.id === discussionId) {
          return {
            ...d,
            title: title || d.title,
            content: content || d.content,
          };
        }
        return d;
      }));
  
      try {
        const data = await fetchWithAuth(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}`,
          {
            method: 'PUT',
            body: JSON.stringify({ title, content }),
          }
        );
  
        setDiscussions(prev => prev.map(d => {
          if (d.id === discussionId) return data;
          return d;
        }));
  
        notifications.show({
          title: 'Success',
          message: 'Discussion updated successfully',
          color: 'green'
        });
  
        return data;
      } catch (err) {
        setDiscussions(prev => prev.map(d => {
          if (d.id === discussionId) return originalDiscussion;
          return d;
        }));
  
        if (err.message !== 'Authentication required') {
          notifications.show({
            title: 'Error',
            message: err.message,
            color: 'red'
          });
        }
        throw err;
      }
    };
  
    const deleteDiscussion = async (discussionId) => {
      if (!isAuthenticated) {
        navigate('/login');
        return;
      }
  
      const originalDiscussion = discussions.find(d => d.id === discussionId);
      if (!originalDiscussion) return;
  
      setDiscussions(prev => prev.filter(d => d.id !== discussionId));
  
      try {
        await fetchWithAuth(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}`,
          { method: 'DELETE' }
        );
  
        notifications.show({
          title: 'Success',
          message: 'Discussion deleted successfully',
          color: 'green'
        });
      } catch (err) {
        setDiscussions(prev => [...prev, originalDiscussion]);
  
        if (err.message !== 'Authentication required') {
          notifications.show({
            title: 'Error',
            message: err.message,
            color: 'red'
          });
        }
        throw err;
      }
    };
  
    const voteDiscussion = async (discussionId) => {
      if (!isAuthenticated) {
        navigate('/login');
        return;
      }
  
      const discussion = discussions.find(d => d.id === discussionId);
      if (!discussion) return;
  
      const isVoted = discussion.has_voted;
      const newVoteCount = discussion.upvote_count + (isVoted ? -1 : 1);
  
      setDiscussions(prev => prev.map(d => {
        if (d.id === discussionId) {
          return {
            ...d,
            upvote_count: newVoteCount,
            has_voted: !isVoted
          };
        }
        return d;
      }));
  
      try {
        const response = await fetchWithAuth(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}/vote`,
          { method: 'POST' }
        );
  
        setDiscussions(prev => prev.map(d => {
          if (d.id === discussionId) {
            return {
              ...d,
              upvote_count: response.upvote_count,
              has_voted: response.message === "Vote added"
            };
          }
          return d;
        }));
      } catch (err) {
        setDiscussions(prev => prev.map(d => {
          if (d.id === discussionId) {
            return {
              ...d,
              upvote_count: discussion.upvote_count,
              has_voted: isVoted
            };
          }
          return d;
        }));
  
        if (err.message !== 'Authentication required') {
          notifications.show({
            title: 'Error',
            message: err.message,
            color: 'red'
          });
        }
        throw err;
      }
    };
  
    useEffect(() => {
      fetchDiscussions();
    }, []);
  
    return {
      discussions,
      loading,
      error,
      createDiscussion,
      updateDiscussion,
      deleteDiscussion,
      voteDiscussion,
      fetchDiscussions, // Now exposed for sorting
    };
  };

  export default useGeneralDiscussions;