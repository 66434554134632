// components/Discussion/Post.js
import { useState } from 'react';
import {
  Stack,
  Title,
  Group,
  Button,
  TextInput,
  Modal,
  Paper,
  Text,
  Card,
  Badge,
  ActionIcon,
  Divider,
  Avatar,
  Box,
  Tooltip,
  Menu,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import {
  ThumbsUp,
  MessageCircle,
  Share,
  Flag,
  MoreHorizontal,
  Code,
  Image,
  Table,
  Link as LinkIcon,
} from 'lucide-react';

import AgentInfoToggle from './AgentInfoToggle';

const DiscussionCard = ({ discussion, onVote, onClick }) => {
  // Handler for vote button click
  const handleVoteClick = (e) => {
    e.stopPropagation(); // Prevent card click when voting
    onVote(discussion.id);
  };

  return (
    <Card 
      withBorder 
      shadow="sm" 
      radius="md" 
      className="cursor-pointer hover:shadow-md transition-shadow duration-200"
      onClick={onClick}
    >
      <Stack spacing="sm">
        <Group justify="space-between" wrap="nowrap">
          <Group wrap="nowrap">
            <Avatar 
              src={discussion.author_avatar} 
              size="md" 
              radius="xl"
            />
            <Box className="min-w-0"> {/* Prevent text overflow */}
              <Text size="lg" fw={500} lineClamp={1}>
                {discussion.title}
              </Text>
              <Text size="sm" c="dimmed" lineClamp={1}>
                Started by {discussion.created_by_username} • {new Date(discussion.created_at_ts).toLocaleString()}
              </Text>
            </Box>
          </Group>

          <Group gap="xs" wrap="nowrap">
            {discussion.pinned && (
              <Badge color="blue" variant="light">Pinned</Badge>
            )}
            <Box className="flex gap-1 overflow-x-auto">
              {discussion.tags?.map(tag => (
                <Badge 
                  key={tag.id} 
                  variant="light"
                  onClick={(e) => e.stopPropagation()}
                  className="whitespace-nowrap"
                >
                  {tag.name}
                </Badge>
              ))}
            </Box>
          </Group>
        </Group>

        {discussion.agent_info && (
          <AgentInfoToggle 
          competitionId={discussion.competition_id}
            agentInfo={discussion.agent_info}  
            evaluationType={discussion.evaluation_type}
          />
        )}

        <Group>
          <Button.Group>
            <Button 
              variant="subtle" 
              size="xs"
              leftSection={<ThumbsUp size={16} />}
              onClick={handleVoteClick}
              color={discussion.has_voted ? 'blue' : 'gray'}
            >
              {discussion.upvote_count}
            </Button>
            
            <Button
              variant="subtle"
              size="xs"
              leftSection={<MessageCircle size={16} />}
              onClick={(e) => e.stopPropagation()}
            >
              {discussion.post_count} Replies
            </Button>
          </Button.Group>
        </Group>
      </Stack>
    </Card>
  );
};

export default DiscussionCard;