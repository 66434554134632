import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Group,
  Button,
  Paper,
  Text,
  ActionIcon,
  Avatar,
  Box,
  Menu,
  Modal,
} from '@mantine/core';
import {
  ThumbsUp,
  MessageCircle,
  Pencil,
  Trash,
  MoreHorizontal,
} from 'lucide-react';
import { PostContent } from './PostEditor';
import PostEditor from './PostEditor';

const Post = ({ 
  post, 
  onVote, 
  onReply,
  onEdit,
  onDelete,
  currentUser,
  isAuthenticated,
  isReply = false,
  updatePost
}) => {
  const navigate = useNavigate();
  const [showReplyEditor, setShowReplyEditor] = useState(false);
  const [showEditEditor, setShowEditEditor] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleAuthAction = (action) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    action();
  };

  const handleVote = () => {
    handleAuthAction(() => onVote?.(post.id));
  };

  const handleReplyClick = () => {
    handleAuthAction(() => setShowReplyEditor(!showReplyEditor));
  };

  const handleEdit = async (content) => {
    try {
      await updatePost(post.id, content);
      setShowEditEditor(false);
    } catch (error) {
      console.error("Failed to update post:", error);
    }
  };

  const handleDelete = () => {
    onDelete?.(post.id);
    setShowDeleteModal(false);
  };

  return (
    <Paper 
      withBorder 
      p="md"
      ml={isReply ? "md" : 0}
      mt={isReply ? "xs" : 0}
      mb={isReply ? 0 : "md"}
      radius="md"
    >
      <Stack spacing="sm">
        <Group justify="space-between">
          <Group>
            <Avatar 
              src={post.author_avatar} 
              radius="xl"
              size="sm"
            />
            <Box>
              <Text size="sm" fw={500}>
                {post.created_by_username || "Unknown"}
              </Text>
              <Text size="xs" c="dimmed">
                {new Date(post.created_at_ts).toLocaleString()}
              </Text>
            </Box>
          </Group>
          {currentUser?.user_id === post.created_by_id && (
          <Menu position="bottom-end">
            <Menu.Target>
              <ActionIcon variant="subtle">
                <MoreHorizontal size={16} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item 
                leftSection={<Pencil size={14} />}
                onClick={() => setShowEditEditor(true)}
              >
                Edit
              </Menu.Item>
              <Menu.Item 
                leftSection={<Trash size={14} />}
                color="red"
                onClick={() => setShowDeleteModal(true)}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          )}
        </Group>

        <Box>
          {showEditEditor ? (
            <PostEditor
              key={`edit-${post.id}`}
              initialContent={post.content_html}
              onSubmit={handleEdit}
              onCancel={() => setShowEditEditor(false)}
              submitLabel="Save Changes"
            />
          ) : (
            post.content_html && <PostContent content={post.content_html} />
          )}
        </Box>

        <Group>
          <Button.Group>
            <Button 
              variant="subtle" 
              size="xs"
              leftSection={<ThumbsUp size={16} />}
              onClick={handleVote}
              color={post.has_voted ? 'blue' : 'gray'}
            >
              {post.upvote_count || 0}
            </Button>
            
            <Button
              variant="subtle"
              size="xs"
              leftSection={<MessageCircle size={16} />}
              onClick={handleReplyClick}
            >
              Reply
            </Button>
          </Button.Group>
        </Group>

        {showReplyEditor && (
          <Box ml={isReply ? 0 : "md"}>
            <PostEditor
              onSubmit={(content) => {
                onReply?.(content);
                setShowReplyEditor(false);
              }}
              onCancel={() => setShowReplyEditor(false)}
              submitLabel="Post Reply"
              placeholder="Write a reply..."
            />
          </Box>
        )}

        {post.replies?.length > 0 && (
          <Stack ml="md">
            {post.replies.map(reply => (
              <Post
                key={reply.id}
                post={reply}
                onVote={onVote}
                onReply={onReply}
                onEdit={onEdit}
                onDelete={onDelete}
                isAuthenticated={isAuthenticated}
                currentUser={currentUser}
                isReply
              />
            ))}
          </Stack>
        )}

        <Modal
          opened={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          title="Delete Comment?"
          centered
        >
          <Stack>
            <Text size="sm">
              You're about to permanently delete a comment. This cannot be undone. 
              If there are replies on the comment, they will remain on the site. 
              Any medals on the comment will be removed.
            </Text>

            <Group justify="flex-end" mt="md">
              <Button variant="light" onClick={() => setShowDeleteModal(false)}>
                Cancel
              </Button>
              <Button color="red" onClick={handleDelete}>
                Delete
              </Button>
            </Group>
          </Stack>
        </Modal>
      </Stack>
    </Paper>
  );
};

export default Post;