import React, { useEffect, useState } from 'react';
import { Stack, TextInput, Textarea, Button, Group } from '@mantine/core';
import axios from 'axios';
import { notifications } from '@mantine/notifications';

const EnvironmentFields = ({ objectId }) => {
  const [environmentData, setEnvironmentData] = useState({
    state_shape: '',
    observation_shape: '',
    possible_agents: '',
    action_mask: '',
    model_code_import: '',
    url_code_source: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (objectId) {
      fetchEnvironmentData();
    }
  }, [objectId]);

  const fetchEnvironmentData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PREFIX_BACKEND}/api/repositories/environment/${objectId}`);
      const { state_shape, observation_shape, possible_agents, action_mask, model_code_import, url_code_source } = response.data;
      setEnvironmentData({ state_shape, observation_shape, possible_agents, action_mask, model_code_import, url_code_source });
    } catch (error) {
      console.error('Error fetching environment data:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch environment data',
        color: 'red',
      });
    }
  };

  const handleInputChange = (field, value) => {
    setEnvironmentData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_PREFIX_BACKEND}/api/repositories/environment/${objectId}`, environmentData);
      notifications.show({
        title: 'Success',
        message: 'Environment data saved successfully',
        color: 'green',
      });
    } catch (error) {
      console.error('Error updating environment data:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to save environment data',
        color: 'red',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing="md">
      <TextInput
        label="State Shape"
        value={environmentData.state_shape}
        onChange={(event) => handleInputChange('state_shape', event.target.value)}
      />
      <TextInput
        label="Observation Shape"
        value={environmentData.observation_shape}
        onChange={(event) => handleInputChange('observation_shape', event.target.value)}
      />
      <TextInput
        label="possible_agents"
        value={environmentData.possible_agents}
        onChange={(event) => handleInputChange('possible_agents', event.target.value)}
      />
      <TextInput
        label="Action Mask"
        value={environmentData.action_mask}
        onChange={(event) => handleInputChange('action_mask', event.target.value)}
      />
      <Textarea
        label="Model Code Import"
        value={environmentData.model_code_import}
        onChange={(event) => handleInputChange('model_code_import', event.target.value)}
      />
      <TextInput
        label="URL Code Source"
        value={environmentData.url_code_source}
        onChange={(event) => handleInputChange('url_code_source', event.target.value)}
      />
      <Group position="right">
        <Button onClick={handleSave} loading={isLoading}>
          Save Changes
        </Button>
      </Group>
    </Stack>
  );
};

export default EnvironmentFields;