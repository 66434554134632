import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Stack,
  Text,
  Title,
  Container,
  Paper,
  Group,
} from '@mantine/core';
import { IconBrandGoogle, IconBrandGithub, IconMail } from '@tabler/icons-react';
import { useAuth } from 'context/AuthContext';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSocialLogin = async (provider) => {
    await login(provider);
    navigate('/listcompetition');
  };

  return (
    <Container size={420} my={40}>
      <Title 
        align="center" 
        sx={(theme) => ({ 
          fontFamily: `Greycliff CF, ${theme.fontFamily}`, 
          fontWeight: 900 
        })}
      >
        Welcome!
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Stack spacing="md">
          <Button 
            onClick={() => handleSocialLogin('google')}
            variant="default"
            fullWidth
          >
            <Group spacing="sm">
              <IconBrandGoogle size={18} />
              <Text>Sign in/up with Google</Text>
            </Group>
          </Button>
          
          <Button 
            onClick={() => handleSocialLogin('github')}
            variant="default"
            fullWidth
          >
            <Group spacing="sm">
              <IconBrandGithub size={18} />
              <Text>Sign in/up with GitHub</Text>
            </Group>
          </Button>
          
          <Button 
            onClick={() => navigate('/login-mail/sign-in')}
            variant="default"
            fullWidth
          >
            <Group spacing="sm">
              <IconMail size={18} />
              <Text>Sign in/up with Email</Text>
            </Group>
          </Button>
        </Stack>

        <Text color="dimmed" size="sm" align="center" mt={20}>
          By signing in, you agree to our Terms of Service and Privacy Policy.
        </Text>
      </Paper>
    </Container>
  );
};

export default Login;