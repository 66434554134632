import { useState, useEffect } from "react";
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

const useDiscussionsCompetition = (isAuthenticated, competitionId) => {
  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchWithAuth = async (url, options = {}) => {
    const isWrite = options.method && options.method !== 'GET';
    
    if (isWrite && !isAuthenticated) {
      navigate('/login');
      throw new Error('Authentication required');
    }

    const response = await fetch(url, {
      ...options,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || 'Request failed');
    }
    
    return response.json();
  };
// useDiscussionsCompetition hook
const searchAgents = async (searchTerm, agentId = null) => {
  if (!competitionId) return [];
  
  try {
    let url = `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/competition/${competitionId}/search-agents`;
    
    // If agentId is provided, use it instead of search term
    if (agentId) {
      url += `?agent_id=${agentId}`;
    } else if (searchTerm?.trim()) {
      url += `?q=${encodeURIComponent(searchTerm)}`;
    }

    const response = await fetchWithAuth(url);
    return Array.isArray(response) ? response : [];
  } catch (err) {
    if (err.message !== 'Authentication required') {
      notifications.show({
        title: 'Error',
        message: 'Failed to search agents',
        color: 'red'
      });
    }
    return [];
  }
};
const fetchDiscussions = async (sortBy = 'recent', agentAttachedId = null) => {
  setLoading(true);
  try {
    let url = `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/competition/${competitionId}/discussions?sort_by=${sortBy}`;
    
    if (agentAttachedId) {
      url += `&agent_attached_id=${agentAttachedId}`;
    }
    
    const discussionsData = await fetchWithAuth(url);
    setDiscussions(discussionsData);
    setError(null);
  } catch (err) {
    setError(err.message);
    notifications.show({
      title: 'Error',
      message: 'Failed to load discussions',
      color: 'red'
    });
  } finally {
    setLoading(false);
  }
};

  const createDiscussion = async (title, content,  agentAttachedId = null, tags = []) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const optimisticDiscussion = {
      id: `temp-${Date.now()}`,
      title,
      content,
      content_html: content, // Will be replaced with server response
      tags,
      created_at_ts: new Date().toISOString(),
      upvote_count: 0,
      post_count: 0,
      has_voted: false
    };

    setDiscussions(prev => [optimisticDiscussion, ...prev]);

    try {
      const data = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/competition/${competitionId}/discussions`,
        {
          method: 'POST',
          body: JSON.stringify({ 
            title, 
            content, 
            tags,
            agent_attached_id: agentAttachedId 
          }),
        }
      );
      
      setDiscussions(prev => [
        data,
        ...prev.filter(d => d.id !== optimisticDiscussion.id)
      ]);

      notifications.show({
        title: 'Success',
        message: 'Discussion created successfully',
        color: 'green'
      });
      
      return data;
    } catch (err) {
      setDiscussions(prev => prev.filter(d => d.id !== optimisticDiscussion.id));
      
      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };

  const updateDiscussion = async (discussionId, { title, content, agentAttachedId = null }) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const originalDiscussion = discussions.find(d => d.id === discussionId);
    if (!originalDiscussion) return;

    // Optimistic update
    setDiscussions(prev => prev.map(d => {
      if (d.id === discussionId) {
        return {
          ...d,
          title: title || d.title,
          content: content || d.content,
        };
      }
      return d;
    }));

    try {
      const data = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ 
            title, 
            content,
            agent_attached_id: agentAttachedId 
          }),
        }
      );

      setDiscussions(prev => prev.map(d => {
        if (d.id === discussionId) {
          return data;
        }
        return d;
      }));

      notifications.show({
        title: 'Success',
        message: 'Discussion updated successfully',
        color: 'green'
      });

      return data;
    } catch (err) {
      // Revert on error
      setDiscussions(prev => prev.map(d => {
        if (d.id === discussionId) {
          return originalDiscussion;
        }
        return d;
      }));

      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };

  const deleteDiscussion = async (discussionId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const originalDiscussion = discussions.find(d => d.id === discussionId);
    if (!originalDiscussion) return;

    // Optimistic delete
    setDiscussions(prev => prev.filter(d => d.id !== discussionId));

    try {
      await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}`,
        { method: 'DELETE' }
      );

      notifications.show({
        title: 'Success',
        message: 'Discussion deleted successfully',
        color: 'green'
      });
    } catch (err) {
      // Revert on error
      setDiscussions(prev => [...prev, originalDiscussion]);

      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };

  const voteDiscussion = async (discussionId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const discussion = discussions.find(d => d.id === discussionId);
    if (!discussion) return;

    const isVoted = discussion.has_voted;
    const newVoteCount = discussion.upvote_count + (isVoted ? -1 : 1);

    setDiscussions(prev => prev.map(d => {
      if (d.id === discussionId) {
        return {
          ...d,
          upvote_count: newVoteCount,
          has_voted: !isVoted
        };
      }
      return d;
    }));

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_PREFIX_BACKEND}/api/discussions/${discussionId}/vote`,
        { method: 'POST' }
      );

      setDiscussions(prev => prev.map(d => {
        if (d.id === discussionId) {
          return {
            ...d,
            upvote_count: response.upvote_count,
            has_voted: response.message === "Vote added"
          };
        }
        return d;
      }));
    } catch (err) {
      // Revert on error
      setDiscussions(prev => prev.map(d => {
        if (d.id === discussionId) {
          return {
            ...d,
            upvote_count: discussion.upvote_count,
            has_voted: isVoted
          };
        }
        return d;
      }));

      if (err.message !== 'Authentication required') {
        notifications.show({
          title: 'Error',
          message: err.message,
          color: 'red'
        });
      }
      throw err;
    }
  };

  return {
    discussions,
    loading,
    error,
    createDiscussion,
    updateDiscussion,
    deleteDiscussion,
    voteDiscussion,
    fetchDiscussions,
    searchAgents
  };
};

export default useDiscussionsCompetition;