import { useState } from 'react';
import { Stack, Group, Text, Button, Code, Box, Tooltip, ActionIcon, Skeleton } from '@mantine/core';
import { Copy } from 'lucide-react';
import { useClipboard } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Collapse } from '@mantine/core';

const RuntimeView = ({ runtime = {}, isLoading = false, error = null }) => {
  const clipboard = useClipboard({ timeout: 500 });
  const [isExpanded, setIsExpanded] = useState(false);

  if (error) {
    return (
      <Text color="red" size="sm">
        {error}
      </Text>
    );
  }

  if (isLoading) {
    return (
      <Stack spacing="xs">
        <Skeleton height={24} width="60%" />
        <Skeleton height={20} width="40%" />
      </Stack>
    );
  }

  if (!runtime || Object.keys(runtime).length === 0) {
    return (
      <Text color="dimmed" size="sm">
        No runtime information available
      </Text>
    );
  }

  const {
    language,
    language_version,
    framework,
    framework_version,
    requirement
  } = runtime;

  return (
    <Stack spacing="xs">
      <Group spacing="md">
        <Group spacing={4}>
          <Text size="sm" weight={500}>Language:</Text>
          <Text size="sm">
            {language} {language_version}
          </Text>
        </Group>

        {framework && framework !== 'no_framework' && (
          <>
            <Text size="sm" color="dimmed">|</Text>
            <Group spacing={4}>
              <Text size="sm" weight={500}>Framework:</Text>
              <Text size="sm">
                {framework} {framework_version}
              </Text>
            </Group>
          </>
        )}
      </Group>

      {requirement && (
        <>
          <Button 
            variant="subtle" 
            compact 
            onClick={() => setIsExpanded(!isExpanded)}
            rightIcon={isExpanded ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
          >
            requirements.txt
          </Button>
          <Collapse in={isExpanded}>
            <Box pos="relative">
              <Code block p="md" style={{ paddingRight: '40px' }}>
                {requirement}
              </Code>
              <Tooltip
                label={clipboard.copied ? "Copied!" : "Copy requirements.txt"}
                position="left"
              >
                <ActionIcon
                  variant="filled"
                  color="blue"
                  size="lg"
                  style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    zIndex: 1,
                  }}
                  onClick={() => clipboard.copy(requirement)}
                >
                  <Copy size={18} />
                </ActionIcon>
              </Tooltip>
            </Box>
          </Collapse>
        </>
      )}
    </Stack>
  );
};

export default RuntimeView;