import React, { useState } from 'react';
import { Stack, Button, Text, Paper, Group, ActionIcon, Divider, Menu, Modal, Badge } from '@mantine/core';
import { MessageCircle, ThumbsUp, Pencil , MoreHorizontal, Trash } from 'lucide-react';
import useDiscussionCompetitionPosts from 'hooks/competition/useDiscussionCompetitionPosts';
import PostEditor from './PostEditor';
import { PostContent } from './PostEditor';
import CommentThread from './CommentThread';
import DiscussionEditor from 'components/Discussion/DiscussionEditor';
import AgentInfoToggle from './AgentInfoToggle';

const DiscussionDetail = ({ isAuthenticated, currentUser, competitionId, discussion, onBack, updateDiscussion, deleteDiscussion, voteDiscussion,isCompetitionDiscussion=false }) => {
  const {
    posts,
    loading,
    error,
    createPost,
    updatePost,
    deletePost,
    votePost,
  } = useDiscussionCompetitionPosts(isAuthenticated, competitionId, discussion.id);

  const [editingPostId, setEditingPostId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (loading) return <Text>Loading discussion...</Text>;
  if (error) return <Text color="red">{error}</Text>;
  if (!discussion) return <Text>Discussion not found</Text>;

  const handleEdit = async (title, content, agentAttachedId=null) => {
    await updateDiscussion(discussion.id, { title, content, agentAttachedId });
    setShowEditModal(false);
  };

  const handleDelete = async () => {
    await deleteDiscussion(discussion.id);
    onBack();
  };

  const handleReply = async (parentId, content) => {
    const contentToCheck = typeof content === 'string' ? content : content?.toString() || '';
    const plainText = contentToCheck.replace(/<[^>]*>/g, '').trim();
    if (!plainText) return;
    await createPost(content, parentId);
  };

  const threadedPosts = posts.reduce((acc, post) => {
    if (!post.parent_id) {
      acc.rootPosts.push(post);
    } else {
      if (!acc.replies[post.parent_id]) {
        acc.replies[post.parent_id] = [];
      }
      acc.replies[post.parent_id].push(post);
    }
    return acc;
  }, { rootPosts: [], replies: {} });
  return (
    <Stack spacing="md">
      <Group justify="space-between">
        <Button 
          variant="subtle" 
          onClick={onBack} 
          leftSection={<MessageCircle size={16} />}
        >
          Back to Discussions
        </Button>
      </Group>

      {/* Discussion header and content */}
      <Paper withBorder p="xl">
        <Stack spacing="md">
          <Group justify="space-between" align="start">
            <Stack spacing="xs">
              <Text size="xl" fw={700}>{discussion.title}</Text>
              <Group spacing="xs">
                <Text size="sm" c="dimmed">
                  Posted by {discussion.created_by_username || 'Unknown'}
                </Text>
                <Text size="sm" c="dimmed">•</Text>
                <Text size="sm" c="dimmed">
                  {new Date(discussion.created_at_ts).toLocaleDateString()}
                </Text>
              </Group>
            </Stack>
            {discussion.agent_info && (
              <AgentInfoToggle competitionId={discussion.competition_id} agentInfo={discussion.agent_info}  evaluationType={discussion.evaluation_type}/>
          )}
            <Group>
              {isAuthenticated && (
                <ActionIcon 
                  variant="light" 
                  onClick={() => voteDiscussion(discussion.id)}
                  size="lg"
                >
                  <ThumbsUp size={18} />
                </ActionIcon>
              )}
              <Text size="sm">{discussion.upvote_count}</Text>

              {currentUser?.user_id === discussion.created_by_id && (
                <Menu position="bottom-end">
                  <Menu.Target>
                    <ActionIcon variant="subtle">
                      <MoreHorizontal size={16} />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item 
                      leftSection={<Pencil size={14} />}
                      onClick={() => setShowEditModal(true)}
                    >
                      Edit
                    </Menu.Item>
                    <Menu.Item 
                      leftSection={<Trash size={14} />}
                      color="red"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </Group>
          </Group>

          {discussion.tags?.length > 0 && (
            <Group gap="xs">
              {discussion.tags.map(tag => (
                <Paper key={tag.id} py={4} px={8} bg="gray.1">
                  <Text size="xs">{tag.name}</Text>
                </Paper>
              ))}
            </Group>
          )}

          {/* Discussion Content Display */}
          {(discussion.content || discussion.content_html) && (
            <>
              <Divider my="md" />
              <div className="discussion-content">
                {discussion.content_html ? (
                  <div dangerouslySetInnerHTML={{ __html: discussion.content_html }} />
                ) : (
                  <Text>{discussion.content}</Text>
                )}
              </div>
            </>
          )}
        </Stack>
      </Paper>

      {/* Comments section */}
      <Paper withBorder p="md">
        <Stack spacing="lg">
          <Text fw={600}>Comments</Text>
          
          {threadedPosts.rootPosts.map(post => (
            <CommentThread
              key={post.id}
              post={post}
              replies={threadedPosts.replies}
              isAuthenticated={isAuthenticated}
              currentUser={currentUser}
              onReply={handleReply}
              onVote={votePost}
              onEdit={() => setEditingPostId(post.id)}
              onDelete={deletePost}
              editingPostId={editingPostId}
              setEditingPostId={setEditingPostId}
              updatePost={updatePost}
            />
          ))}

          {/* Main comment editor */}
          {isAuthenticated && (
            <PostEditor
              onSubmit={(content) => handleReply(null, content)}
              submitLabel="Comment"
              placeholder="Add a comment..."
            />
          )}

          {/* Auth CTA */}
          {!isAuthenticated && (
            <Paper withBorder p="md" bg="gray.0">
              <Text ta="center" c="dimmed">
                Please <Button variant="subtle" component="a" href="/login">sign in</Button> to join the discussion
              </Text>
            </Paper>
          )}
        </Stack>
      </Paper>
      <Modal
        opened={showEditModal}
        onClose={() => setShowEditModal(false)}
        title="Edit Discussion"
        size="xl"
      >
        <DiscussionEditor
          initialTitle={discussion.title}
          initialContent={discussion.content_html || discussion.content}
          onSubmit={handleEdit}
          onCancel={() => setShowEditModal(false)}
          submitLabel="Save Changes"
          isCompetitionDiscussion={isCompetitionDiscussion}
          competitionId={competitionId}
        />
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        opened={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Delete Discussion"
        centered
      >
        <Stack spacing="md">
          <Text>
            You're about to permanently delete a discussion. This cannot be undone.
            {posts.length > 0 && " If there are posts in the discussion, they will be deleted."}
          </Text>
          
          <Group justify="flex-end">
            <Button variant="subtle" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button color="red" onClick={handleDelete}>
              Delete Discussion
            </Button>
          </Group>
        </Stack>
      </Modal>

    </Stack>
  );
};

export default DiscussionDetail;