import React, { useState } from 'react';
import { Stack } from '@mantine/core';
import Post from './Post';
import PostEditor from './PostEditor';

const CommentThread = ({ 
  post,
  replies,
  depth = 0,
  maxDepth = 8,
  isAuthenticated,
  currentUser,
  onReply,
  onVote,
  onEdit,
  onDelete,
  editingPostId,
  setEditingPostId,
  updatePost
}) => {
  const indent = Math.min(depth * 48, maxDepth * 48);
  const [replyContent, setReplyContent] = useState('');
  
  const canManagePost = (post) => {
    if (!currentUser) return false;
    return post.created_by_id === currentUser.user_id;
  };
  
  const handlePostUpdate = async (postId, content) => {
    try {
      await updatePost(postId, content);
      setEditingPostId(null);
    } catch (error) {
      console.error("Failed to update post:", error);
    }
  };
  
  return (
    <Stack spacing="md">
      {editingPostId === post.id ? (
        <PostEditor
          key={`edit-${post.id}`}
          initialContent={post.content_html}
          onSubmit={(content) => handlePostUpdate(post.id, content)}
          onCancel={() => setEditingPostId(null)}
          submitLabel="Save Changes"
        />
      ) : (
        <Post
          post={post}
          isReply={depth > 0}
          onVote={isAuthenticated ? () => onVote(post.id) : undefined}
          onReply={isAuthenticated ? (content) => onReply(post.id, content) : undefined}
          onEdit={canManagePost(post) ? () => setEditingPostId(post.id) : undefined}
          onDelete={canManagePost(post) ? () => onDelete(post.id) : undefined}
          currentUser={currentUser}
          isAuthenticated={isAuthenticated}
          updatePost={handlePostUpdate}
        />
      )}
      
      {replies[post.id]?.length > 0 && (
        <Stack spacing="md" style={{ marginLeft: indent }}>
          {replies[post.id].map(reply => (
            <CommentThread
              key={reply.id}
              post={reply}
              replies={replies}
              depth={depth + 1}
              maxDepth={maxDepth}
              isAuthenticated={isAuthenticated}
              currentUser={currentUser}
              onReply={onReply}
              onVote={onVote}
              onEdit={onEdit}
              onDelete={onDelete}
              editingPostId={editingPostId}
              setEditingPostId={setEditingPostId}
              updatePost={updatePost}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default CommentThread;