import { useState, useEffect } from 'react';
import axios from 'axios';

export const useRuntimeOptions = (competitionId, isFetchAll = true, agentAttachedId = null) => {
  const [runtimeState, setRuntimeState] = useState({
    options: isFetchAll ? [] : null, // Initialize as array for all options, object for single runtime
    selectedRuntime: null,
    isLoading: true,
    error: null,
    isUpdating: false
  });

  useEffect(() => {
    const fetchRuntimeOptions = async () => {
      if (!competitionId) return;
  
      try {
        setRuntimeState(prev => ({ ...prev, isLoading: true, error: null }));
        
        if (isFetchAll) {
          // Fetch all runtime options
          const optionsResponse = await axios.get(`/api/direct_attache_agents/runtime_options/${competitionId}`);
          
          // Also fetch specific runtime if agentAttachedId is provided
          if (agentAttachedId) {
            try {
              const agentRuntimeResponse = await axios.get(`/api/direct_attache_agents/agent_runtime/${agentAttachedId}`);
              setRuntimeState(prev => ({
                ...prev,
                options: optionsResponse.data,
                selectedRuntime: agentRuntimeResponse.data.id.toString(),
                isLoading: false
              }));
            } catch (agentError) {
              console.error('Agent runtime fetch error:', agentError);
              setRuntimeState(prev => ({
                ...prev,
                options: optionsResponse.data,
                isLoading: false,
                error: agentError.response?.data?.error || 'Failed to fetch agent runtime'
              }));
            }
          } else {
            setRuntimeState(prev => ({
              ...prev,
              options: optionsResponse.data,
              isLoading: false
            }));
          }
        } else {
          // Fetch only specific runtime
          if (!agentAttachedId) {
            throw new Error('Agent ID required when isFetchAll is false');
          }
          
          const agentRuntimeResponse = await axios.get(`/api/direct_attache_agents/agent_runtime/${agentAttachedId}`);
          setRuntimeState(prev => ({
            ...prev,
            options: agentRuntimeResponse.data, // Single runtime object
            isLoading: false
          }));
        }
      } catch (error) {
        console.error('Runtime fetch error:', error);
        setRuntimeState(prev => ({
          ...prev,
          error: error.response?.data?.error || error.message || 'Failed to fetch runtime data',
          isLoading: false
        }));
      }
    };
  
    fetchRuntimeOptions();
  }, [competitionId, agentAttachedId, isFetchAll]);

  const setSelectedRuntime = async (runtimeId) => {
    if (!agentAttachedId || !runtimeId) return;

    try {
      setRuntimeState(prev => ({
        ...prev,
        isUpdating: true,
        error: null,
        selectedRuntime: runtimeId
      }));

      await axios.put(`/api/direct_attache_agents/agent_runtime/${agentAttachedId}`, {
        docker_image_agent_runtime_id: parseInt(runtimeId, 10)
      });

      // Refresh runtime data after update if not fetching all
      if (!isFetchAll) {
        const updatedRuntime = await axios.get(`/api/direct_attache_agents/agent_runtime/${agentAttachedId}`);
        setRuntimeState(prev => ({
          ...prev,
          options: updatedRuntime.data,
          isUpdating: false
        }));
      } else {
        setRuntimeState(prev => ({ ...prev, isUpdating: false }));
      }
    } catch (error) {
      console.error('Runtime update error:', error);
      setRuntimeState(prev => ({
        ...prev,
        error: error.response?.data?.error || 'Failed to update runtime',
        isUpdating: false
      }));
    }
  };

  return {
    ...runtimeState,
    setSelectedRuntime
  };
};