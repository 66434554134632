import React, { useState } from 'react';
import { Paper, Title, Stack, Group, Text, Loader, Modal, Divider } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconFileText, IconEye, IconDownload, IconBrandCodesandbox } from '@tabler/icons-react';
import { List, ThemeIcon, Button, Tooltip } from '@mantine/core';

const useColabIntegration = (competitionId, attacheAgentId) => {
    const [isColabLoading, setIsColabLoading] = useState(false);
  
    const handleColabOpen = async (file) => {
      setIsColabLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_PREFIX_BACKEND}/api/direct_attache_agents_notebook/competition/${competitionId}/${attacheAgentId}/notebook-colab`,
          {
            method: 'POST',
            credentials: 'include',
          }
        );
        if (!response.ok) {
          throw new Error('Failed to create Colab link');
        }
        
        const { colab_url } = await response.json();
        window.open(colab_url, '_blank');
        
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to open notebook in Colab. Please try again.',
          color: 'red'
        });
      } finally {
        setIsColabLoading(false);
      }
    };
  
    return {
      handleColabOpen,
      isColabLoading
    };
  };

  export default useColabIntegration;