import React, { useState, useEffect } from 'react';
import { Paper, Select, Code, Tooltip, ActionIcon, Box, Stack, Button, Group, Text, Collapse } from '@mantine/core';
import { Copy, ChevronDown, ChevronUp, ChevronRight } from 'lucide-react';
import { useClipboard } from '@mantine/hooks';

const RuntimeSelector = ({ 
  runtimeOptions = [], 
  selectedRuntime,
  setSelectedImage,
  isLoading = false 
}) => {
  const clipboard = useClipboard({ timeout: 500 });
  const [isExpanded, setIsExpanded] = useState(false);
  // Create filtered lists for each dropdown
  const languages = [...new Set(runtimeOptions.map(opt => opt.language))].map(lang => ({
    value: lang,
    label: lang.charAt(0).toUpperCase() + lang.slice(1)
  }));

  const getVersionsForLanguage = (language) => {
    return [...new Set(runtimeOptions
      .filter(opt => opt.language === language)
      .map(opt => opt.language_version)
    )].map(version => ({
      value: version,
      label: `${version}`
    }));
  };

  const getFrameworksForVersion = (language, version) => {
    return [...new Set(runtimeOptions
      .filter(opt => opt.language === language && opt.language_version === version)
      .map(opt => opt.framework)
    )].map(framework => ({
      value: framework,
      label: framework === 'no_framework' ? 'No Framework' : framework
    }));
  };

  const getFrameworkVersions = (language, version, framework) => {
    const filteredOptions = runtimeOptions.filter(opt => 
      opt.language === language && 
      opt.language_version === version && 
      opt.framework === framework
    );
    
    // If we have a selected runtime that matches our current filters, put it first
    const sortedOptions = [...filteredOptions].sort((a, b) => {
      if (a.id.toString() === selectedRuntime?.toString()) return -1;
      if (b.id.toString() === selectedRuntime?.toString()) return 1;
      return 0;
    });
    
    return sortedOptions.map(opt => ({
      value: opt.id.toString(),
      label: opt.framework_version === 'no_framework' ? 'None' : opt.framework_version
    }));
  };

  // Get current selection details
  const currentRuntime = runtimeOptions.find(opt => opt.id.toString() === selectedRuntime?.toString());
  const currentRequirements = currentRuntime?.requirement || '';

  // Maintain selected values - Initialize with current runtime if available
  const [selected, setSelected] = useState({
    language: currentRuntime?.language || null,
    languageVersion: currentRuntime?.language_version || null,
    framework: currentRuntime?.framework || null,
  });

  // Update selected values when currentRuntime changes
  useEffect(() => {
    if (currentRuntime) {
      setSelected({
        language: currentRuntime.language,
        languageVersion: currentRuntime.language_version,
        framework: currentRuntime.framework,
      });
    }
  }, [currentRuntime]);

  // Update available options based on selection
  const languageVersionOptions = selected.language ? getVersionsForLanguage(selected.language) : [];
  const frameworkOptions = selected.languageVersion ? 
    getFrameworksForVersion(selected.language, selected.languageVersion) : [];
  const frameworkVersionOptions = selected.framework ? 
    getFrameworkVersions(selected.language, selected.languageVersion, selected.framework) : [];

  // Handle selection changes
  const handleLanguageChange = (value) => {
    const newSelected = { language: value, languageVersion: null, framework: null };
    setSelected(newSelected);
    
    const versions = getVersionsForLanguage(value);
    if (versions.length) {
      handleLanguageVersionChange(versions[0].value, { ...newSelected, languageVersion: versions[0].value });
    }
  };

  const handleLanguageVersionChange = (value, prevSelected = selected) => {
    const newSelected = { ...prevSelected, languageVersion: value, framework: null };
    setSelected(newSelected);
    
    const frameworks = getFrameworksForVersion(newSelected.language, value);
    if (frameworks.length) {
      handleFrameworkChange(frameworks[0].value, newSelected);
    }
  };

  const handleFrameworkChange = (value, prevSelected = selected) => {
    const newSelected = { ...prevSelected, framework: value };
    setSelected(newSelected);
    
    const versions = getFrameworkVersions(newSelected.language, newSelected.languageVersion, value);
    
    // Select appropriate framework version
    if (versions.length > 0) {
      // If there's a currently selected runtime that matches our filters, keep it
      const currentVersion = versions.find(v => v.value === selectedRuntime);
      if (currentVersion) {
        setSelectedImage(currentVersion.value);
      } else {
        // Otherwise select first available version
        setSelectedImage(versions[0].value);
      }
    }
  };

  // Initialize first selection
  useEffect(() => {
    if (runtimeOptions.length) {
      if (currentRuntime) {
        // If we have a current runtime, set all values including framework version
        setSelected({
          language: currentRuntime.language,
          languageVersion: currentRuntime.language_version,
          framework: currentRuntime.framework
        });
        setSelectedImage(currentRuntime.id.toString());
      } else if (!selected.language) {
        // Initial setup when no runtime is selected
        const firstLang = languages[0]?.value;
        if (firstLang) {
          const firstVersion = getVersionsForLanguage(firstLang)[0]?.value;
          if (firstVersion) {
            const firstFramework = getFrameworksForVersion(firstLang, firstVersion)[0]?.value;
            if (firstFramework) {
              const versions = getFrameworkVersions(firstLang, firstVersion, firstFramework);
              if (versions.length > 0) {
                setSelected({
                  language: firstLang,
                  languageVersion: firstVersion,
                  framework: firstFramework
                });
                setSelectedImage(versions[0].value);
              }
            }
          }
        }
      }
    }
  }, [runtimeOptions, currentRuntime]);

  // New function - Add this to handle runtime updates
useEffect(() => {
  if (selectedRuntime && runtimeOptions.length > 0) {
    const runtime = runtimeOptions.find(opt => opt.id.toString() === selectedRuntime.toString());
    if (runtime) {
      setSelected({
        language: runtime.language,
        languageVersion: runtime.language_version,
        framework: runtime.framework
      });
    }
  }
}, [selectedRuntime, runtimeOptions]);
return (
  <Paper shadow="xs" p="md">
    <Stack spacing="md">
      <Group spacing="lg">
        <Select
          size="md"
          label={
            <Group spacing={4}>
              <Text>Language</Text>
              <Text span c="red">*</Text>
            </Group>
          }
          placeholder="Select language"
          data={languages}
          value={selected.language}
          onChange={handleLanguageChange}
          disabled={isLoading}
          style={{ width: '140px' }}
          classNames={{
            input: 'h-[42px]'
          }}
        />

        <Select
          size="md"
          label={
            <Group spacing={4}>
              <Text>Version</Text>
              <Text span c="red">*</Text>
            </Group>
          }
          placeholder="Select version"
          data={languageVersionOptions}
          value={selected.languageVersion}
          onChange={(value) => handleLanguageVersionChange(value)}
          disabled={!selected.language || isLoading}
          style={{ width: '120px' }}
          classNames={{
            input: 'h-[42px]'
          }}
        />

        <Select
          size="md"
          label={
            <Group spacing={4}>
              <Text>Framework</Text>
              <Text span c="red">*</Text>
            </Group>
          }
          placeholder="Select framework"
          data={frameworkOptions}
          value={selected.framework}
          onChange={(value) => handleFrameworkChange(value)}
          disabled={!selected.languageVersion || isLoading}
          style={{ width: '180px' }}
          classNames={{
            input: 'h-[42px]'
          }}
        />

        <Select
          size="md"
          label={
            <Group spacing={4}>
              <Text>Version</Text>
              <Text span c="red">*</Text>
            </Group>
          }
          placeholder="Select version"
          data={frameworkVersionOptions}
          value={selectedRuntime}
          onChange={setSelectedImage}
          disabled={!selected.framework || isLoading}
          style={{ width: '120px' }}
          classNames={{
            input: 'h-[42px]'
          }}
        />
      </Group>

      {currentRequirements && (
        <>
          <Group spacing="xs" onClick={() => setIsExpanded(!isExpanded)} style={{ cursor: 'pointer' }}>
            <Text variant="link" size="sm">View relative requirements.txt</Text>
            {isExpanded ? 
              <ChevronDown size={16} className="text-gray-500" /> : 
              <ChevronRight size={16} className="text-gray-500" />
            }
          </Group>
          
          <Collapse in={isExpanded}>
            <Box pos="relative">
              <Code block p="md" style={{ paddingRight: '40px' }}>
                {currentRequirements}
              </Code>
              <Tooltip
                label={clipboard.copied ? "Copied!" : "Copy requirements.txt"}
                position="left"
              >
                <ActionIcon
                  variant="subtle"
                  size="sm"
                  style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                  }}
                  onClick={() => clipboard.copy(currentRequirements)}
                >
                  <Copy size={16} />
                </ActionIcon>
              </Tooltip>
            </Box>
          </Collapse>
        </>
      )}
    </Stack>
  </Paper>
);
};

export default RuntimeSelector;