import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // Create a memoized refresh function
  const refreshAuthState = useCallback(async () => {
    try {
      const response = await axios.get('/api/auth/current_user');
      const { data } = response;
      
      if (data?.isAuthenticated) {
        setCurrentUser(data);
      } else {
        setCurrentUser(null);
      }
      
      return data;
    } catch (error) {
      console.error('Failed to refresh auth state:', error);
      setCurrentUser(null);
      return null;
    }
  }, []);

  // Initial auth check
  useEffect(() => {
    refreshAuthState().finally(() => {
      setLoading(false);
    });
  }, [refreshAuthState]);

  // Create an event bus for auth state changes
  useEffect(() => {
    const authChannel = new BroadcastChannel('auth-channel');
    
    authChannel.onmessage = (event) => {
      if (event.data === 'auth-changed') {
        refreshAuthState();
      }
    };
    
    return () => {
      authChannel.close();
    };
  }, [refreshAuthState]);

  const broadcastAuthChange = useCallback(() => {
    const authChannel = new BroadcastChannel('auth-channel');
    authChannel.postMessage('auth-changed');
    authChannel.close();
  }, []);

  const login = useCallback(async (provider) => {
    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const authWindow = window.open(
      `${process.env.REACT_APP_PREFIX_BACKEND}/api/auth/login/${provider}`,
      'AuthWindow',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    if (authWindow) {
      const timer = setInterval(async () => {
        if (authWindow.closed) {
          clearInterval(timer);
          const authState = await refreshAuthState();
          if (authState?.isAuthenticated) {
            broadcastAuthChange();
          }
        }
      }, 1000);
    }
  }, [refreshAuthState, broadcastAuthChange]);

  const logout = useCallback(async () => {
    try {
      await axios.post('/api/auth/logout');
      setCurrentUser(null);
      broadcastAuthChange();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }, [broadcastAuthChange]);

  const value = {
    currentUser,
    isAuthenticated: !!currentUser,
    isAdmin: currentUser?.is_admin,
    login,
    logout,
    refreshAuthState,
    // Add these for backward compatibility
    setCurrentUser,
    setIsAuthenticated: (isAuth) => {
      if (!isAuth) {
        setCurrentUser(null);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;